import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoContent';

// Context
import ContextConsumer from '../components/common/contextProvider';

const TermsPage = ({ data }) => (
  <ContextConsumer>
    {({ context }) => <Content data={data} context={context} />}
  </ContextConsumer>
);

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const { seoContent } = data.contentfulPages;

    return (
      <Fragment>
        <SeoDetails seoContent={seoContent} />
      </Fragment>
    );
  }
}

export const query = graphql`
  query {
    contentfulPages(id: { eq: "ed64ba16-ad4a-5b76-b493-21b0bc858223" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          description
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default TermsPage;
